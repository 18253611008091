.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  position: relative;
}

.spline-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Set the height to 100% of the viewport height */
}

spline-viewer {
  width: 100%;
  height: 100%;
}
.spline-animation {
  position: relative;
  z-index: -1;
  margin-top: -30px; /* Adjust this value to position the SplineAnimation as desired */
}
