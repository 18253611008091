/* ContactUs.css */
.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-image: linear-gradient(
    to bottom,
    #f5f5f5 0%,
    #f5f5f5 100%,
    transparent 85%,
    transparent 100%
  );
}

.contact-intro {
  text-align: center;
  margin-bottom: 2rem;
  color: #4a4a4a;
}

.contact-intro h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333;
}

.contact-intro p {
  font-size: 1rem;
  line-height: 1.5;
  max-width: 600px;
  margin: 0 auto;
}

.contact-sections {
  display: flex;
  width: 100%;
  max-width: 1200px;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative; /* Add this line */
}

.separator {
  position: absolute; /* Add this line */
  top: 0; /* Add this line */
  bottom: 0; /* Add this line */
  left: 50%; /* Add this line */
  transform: translateX(-50%); /* Add this line */
  width: 2px;
  background-image: linear-gradient(
    to bottom,
    transparent 0%,
    #ccc 15%,
    #ccc 85%,
    transparent 100%
  );
}

.instagram-icon {
  font-size: 2rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 400px;
  flex: 1;
}

.contact-form input,
.contact-form textarea,
.contact-form button {
  box-sizing: border-box; /* Add this line */
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.contact-form button {
  padding: 0.75rem;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form textarea {
  min-height: 150px;
  resize: vertical;
}

.contact-form button:hover {
  background-color: #45a049;
}
.contact-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start; /* Updated to align left */
  flex: 1;
  margin-bottom: 2rem;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.icons {
  color: green;
  font-size: 2rem;
  vertical-align: middle; /* Keep this line */
}

.theText {
  text-decoration: none;
  color: inherit;
  font-size: 1.3rem;
  line-height: 2rem;
  margin-left: 1rem;
}

.theText:hover {
  text-decoration: none;
}
@media (max-width: 768px) {
  .contact-sections {
    flex-direction: column-reverse;
    justify-content: normal;
  }

  .separator {
    position: static;
    width: 100%;
    height: 2px;
    margin: 2rem 0;
    background-image: linear-gradient(to right, #ccc 0%, #ccc 100%);
    transform: none;
  }

  .contact-form {
    margin-bottom: 2rem;
  }

  .contact-info {
    align-items: center;
    text-align: center;
  }

  .theText {
    font-size: 1.1rem;
  }

  .icons {
    font-size: 1.5rem;
  }
}
