.about-us-container {
  min-height: 100vh;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  padding: 4rem 2rem;
  position: relative;
  overflow: hidden;
}

.about-us-content {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.floating-words {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 4rem;
}

.floating-word {
  font-size: 1.2rem;
  color: #6c757d;
  font-weight: 500;
  padding: 0.5rem 1.5rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 30px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}

.about-title {
  font-size: 3.5rem;
  margin-bottom: 2rem;
  background: linear-gradient(45deg, #2c3e50, #3498db);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.text-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-bottom: 4rem;
}

.about-paragraph {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #495057;
}

.highlight {
  color: #3498db;
  font-weight: 600;
}

.stats-container {
  display: flex;
  justify-content: space-around;
  margin-top: 4rem;
  flex-wrap: wrap;
  gap: 2rem;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 15px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.stat-number {
  font-size: 2.5rem;
  font-weight: 700;
  color: #3498db;
  margin-bottom: 0.5rem;
}

.stat-label {
  font-size: 1rem;
  color: #6c757d;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.geometric-shapes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.shape {
  position: absolute;
  border-radius: 50%;
  opacity: 0.1;
}

.shape1 {
  width: 300px;
  height: 300px;
  background: linear-gradient(45deg, #3498db, #2ecc71);
  top: -150px;
  left: -150px;
}

.shape2 {
  width: 200px;
  height: 200px;
  background: linear-gradient(45deg, #e74c3c, #f1c40f);
  bottom: -100px;
  right: -100px;
}

.shape3 {
  width: 150px;
  height: 150px;
  background: linear-gradient(45deg, #9b59b6, #3498db);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
  .text-columns {
    grid-template-columns: 1fr;
  }

  .about-title {
    font-size: 2.5rem;
  }

  .floating-words {
    gap: 1rem;
  }

  .floating-word {
    font-size: 1rem;
    padding: 0.4rem 1rem;
  }

  .stats-container {
    flex-direction: column;
    align-items: center;
  }

  .stat-item {
    width: 100%;
    max-width: 300px;
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.project-showcase {
  margin: 4rem 0;
}

.project-item {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 4rem;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
}

.project-item:nth-child(even) {
  flex-direction: row-reverse;
}

.project-image-container {
  flex: 1;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.project-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.project-text {
  flex: 1;
  padding: 1rem;
}

.project-text h3 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #2c3e50, #3498db);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.project-text p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #495057;
}

@media (max-width: 768px) {
  .project-item {
    flex-direction: column !important;
    padding: 1rem;
  }

  .project-image {
    height: 200px;
  }

  .project-text {
    text-align: center;
  }
}
