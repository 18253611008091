.header-container {
  position: relative;
  min-height: 100vh;
  background: #0a192f;
  overflow: hidden;
}

.hero-section {
  display: flex;
  min-height: 100vh;
  position: relative;
}

.image-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.1);
  animation: zoomEffect 20s infinite alternate;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135deg,
    rgba(10, 25, 47, 0.9) 0%,
    rgba(10, 25, 47, 0.7) 100%
  );
}

.hero-content {
  position: relative;
  z-index: 2;
  padding: 2rem;
  width: 60%;
  display: flex;
  align-items: center;
}

.hero-text-container {
  max-width: 800px;
}

.main-title {
  font-size: 4.5rem;
  font-weight: 800;
  line-height: 1.2;
  color: #ffffff;
  margin-bottom: 1.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.4);
  padding: 1rem;
  border-radius: 12px;
  backdrop-filter: blur(5px);
  display: inline-block;
}

.highlight-text {
  display: block;
  background: linear-gradient(45deg, #eaff02, #c5940e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 5rem;
  text-shadow: none;
  margin-top: 0.5rem;
}

.subtitle {
  font-size: 1.5rem;
  color: #ffffff;
  max-width: 600px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.4);
  padding: 1rem;
  border-radius: 8px;
  backdrop-filter: blur(5px);
  display: inline-block;
  margin-top: 1rem;
}

.contact-section {
  position: relative;
  z-index: 2;
  width: 40%;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10px);
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-header {
  width: 100%;
  text-align: center;
  margin-bottom: 4rem;
}

.company-logo {
  width: 60px;
  height: auto;
  margin-bottom: 1rem;
  filter: invert(1);
}

.contact-header h2 {
  color: #2c8f7e;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  position: relative;
  z-index: 2;
  margin-top: -1rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.input-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.input-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #2c8f7e;
  font-size: 1.2rem;
  opacity: 0.8;
}

.input-group input,
.input-group textarea {
  width: 100%;
  padding: 1rem 2.5rem 1rem 1rem;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(44, 143, 126, 0.2);
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.input-group textarea {
  height: 120px;
  resize: none;
  padding-top: 1rem;
}

.input-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #2c8f7e;
  font-size: 1.2rem;
  opacity: 0.8;
}

.textarea-icon {
  top: 1.5rem;
}

.input-group input:focus,
.input-group textarea:focus {
  outline: none;
  border-color: #2c8f7e;
  background: rgba(255, 255, 255, 0.15);
}

.submit-button {
  background: linear-gradient(45deg, #2c8f7e, #1a5f7a);
  color: #ffffff;
  padding: 1rem;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-shadow: none;
}

.submit-button:hover {
  background: linear-gradient(45deg, #1a5f7a, #2c8f7e);
}

.success-message {
  text-align: center;
  padding: 3rem 2rem;
}

.success-content {
  background: rgba(100, 255, 218, 0.1);
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid rgba(100, 255, 218, 0.2);
}

.success-icon {
  width: 60px;
  height: 60px;
  background: #2c8f7e;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
  font-size: 2rem;
  color: #0a192f;
}

.success-content h3 {
  color: #2c8f7e;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.success-content p {
  color: #8892b0;
}

.whatsapp-button {
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  z-index: 100;
  background: #25d366;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 20px rgba(37, 211, 102, 0.3);
}

.whatsapp-button svg {
  font-size: 2rem;
  color: white;
}

.scroll-indicator {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  color: #64ffda;
  font-size: 2rem;
  z-index: 10;
}

@keyframes zoomEffect {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #0a192f;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1200px) {
  .main-title {
    font-size: 3.5rem;
  }

  .highlight-text {
    font-size: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .hero-section {
    flex-direction: column;
  }

  .hero-content {
    width: 100%;
    padding: 2rem 1rem;
    min-height: 60vh;
    text-align: center;
  }

  .main-title {
    font-size: 2.5rem;
    padding: 0.75rem;
  }

  .highlight-text {
    font-size: 3rem;
  }

  .subtitle {
    font-size: 1.2rem;
    padding: 0.75rem;
  }

  .contact-section {
    width: 100%;
    min-height: 40vh;
    padding: 2rem 1rem;
    border-left: none;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .contact-header {
    margin-bottom: 2rem;
  }

  .contact-header h2 {
    margin-top: -2rem;
    font-size: 1.75rem;
    padding: 0.5rem 1.5rem;
  }

  .company-logo {
    width: 80px;
  }

  .input-group {
    margin-bottom: 1rem;
  }

  .input-group input,
  .input-group textarea {
    padding: 0.75rem 2.5rem 0.75rem 1rem;
    font-size: 16px; /* Prevents zoom on iOS */
  }

  .submit-button {
    padding: 0.75rem;
    font-size: 1rem;
  }

  .whatsapp-button {
    bottom: 1rem;
    right: 1rem;
    width: 50px;
    height: 50px;
  }

  .scroll-indicator {
    bottom: 1rem;
    font-size: 1.5rem;
  }

  .header-container {
    min-height: 100vh;
    background: #0a192f;
    overflow: hidden;
  }

  .desktop-only {
    display: none;
  }

  .mobile-content {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    text-align: center;
  }

  .floating-shape {
    position: absolute;
    border-radius: 50%;
    filter: blur(40px);
    z-index: 1;
  }

  .shape1 {
    width: 200px;
    height: 200px;
    background: linear-gradient(45deg, #2c8f7e55, #1a5f7a55);
    top: 10%;
    left: -50px;
  }

  .shape2 {
    width: 150px;
    height: 150px;
    background: linear-gradient(-45deg, #64ffda55, #63b3ed55);
    bottom: 20%;
    right: -30px;
  }

  .mobile-logo {
    width: 120px;
    margin-bottom: 2rem;
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.2));
  }

  .main-title {
    font-size: 2.8rem;
    font-weight: 800;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    margin-bottom: 1rem;
    position: relative;
    z-index: 2;
  }

  .highlight-text {
    display: block;
    background: linear-gradient(45deg, #eaff02, #c5940e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3.2rem;
    margin-top: 0.5rem;
    position: relative;
  }

  .subtitle {
    font-size: 1.2rem;
    color: #ffffff;
    margin: 1.5rem 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    position: relative;
    z-index: 2;
  }

  .cta-container {
    margin-top: 2rem;
    position: relative;
    z-index: 2;
  }

  .cta-button {
    background: linear-gradient(45deg, #2c8f7e, #1a5f7a);
    color: white;
    padding: 1rem 2.5rem;
    border: none;
    border-radius: 30px;
    font-size: 1.2rem;
    font-weight: 600;
    box-shadow: 0 4px 15px rgba(44, 143, 126, 0.3);
    position: relative;
    overflow: hidden;
  }

  .cta-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transition: 0.5s;
  }

  .cta-button:hover::before {
    left: 100%;
  }

  .whatsapp-button {
    bottom: 2rem;
    right: 2rem;
    width: 60px;
    height: 60px;
    background: #25d366;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 15px rgba(37, 211, 102, 0.3);
    z-index: 100;
  }

  .scroll-indicator {
    display: none;
  }

  /* Add cool gradient animation */
  @keyframes gradientBG {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .image-overlay {
    background: linear-gradient(
      135deg,
      rgba(10, 25, 47, 0.9),
      rgba(44, 143, 126, 0.8),
      rgba(26, 95, 122, 0.9)
    );
    background-size: 200% 200%;
    animation: gradientBG 15s ease infinite;
  }
}

/* Tablet Styles */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .hero-content {
    padding: 3rem 2rem;
  }

  .main-title {
    font-size: 3.5rem;
  }

  .highlight-text {
    font-size: 4rem;
  }

  .contact-section {
    padding: 2.5rem;
  }
}

/* Landscape Mode */
@media screen and (max-width: 768px) and (orientation: landscape) {
  .hero-section {
    flex-direction: row;
  }

  .hero-content {
    width: 60%;
    min-height: 100vh;
  }

  .contact-section {
    width: 40%;
    min-height: 100vh;
  }

  .main-title {
    font-size: 2rem;
  }

  .highlight-text {
    font-size: 2.5rem;
  }

  .contact-header h2 {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 769px) {
  .mobile-content {
    display: none;
  }

  .hero-section {
    display: flex;
    min-height: 100vh;
  }

  .hero-content {
    position: relative;
    z-index: 2;
    padding: 2rem;
    width: 60%;
    display: flex;
    align-items: center;
  }

  .contact-section {
    position: relative;
    z-index: 2;
    width: 40%;
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(10px);
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto; /* Push to right side */
  }

  .main-title {
    font-size: 4.5rem;
    font-weight: 800;
    line-height: 1.2;
    color: #ffffff;
    margin-bottom: 1.5rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    background: rgba(0, 0, 0, 0.4);
    padding: 1rem;
    border-radius: 12px;
    backdrop-filter: blur(5px);
    display: inline-block;
  }

  .highlight-text {
    display: block;
    background: linear-gradient(45deg, #eaff02, #c5940e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 5rem;
    text-shadow: none;
    margin-top: 0.5rem;
  }

  .subtitle {
    font-size: 1.5rem;
    color: #ffffff;
    max-width: 600px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    background: rgba(0, 0, 0, 0.4);
    padding: 1rem;
    border-radius: 8px;
    backdrop-filter: blur(5px);
    display: inline-block;
    margin-top: 1rem;
  }
}
