/* Projects.css */
.projects {
  padding: 4rem 2rem;
  background-color: #fff;
  font-family: Arial, sans-serif;
  overflow: hidden;
}

.projects h2 {
  font-size: 40px;
  color: #333;
  margin-bottom: 20px;
  font-weight: 600;
}

.projects-slider {
  margin: 0 -30px;
  padding: 20px 0;
}

.project-slide {
  padding: 0 30px;
  cursor: pointer;
}

.project-card {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.project-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  display: block;
  transition: transform 0.5s ease;
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.project-overlay span {
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 10px 20px;
  border: 2px solid white;
  border-radius: 25px;
  background: rgba(0, 0, 0, 0.3);
}

.project-card:hover .project-overlay {
  opacity: 1;
}

.project-card:hover .project-image {
  transform: scale(1.05);
}

/* Slider customization */
.slick-dots {
  bottom: -40px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #333;
  opacity: 0.3;
  transition: all 0.3s ease;
}

.slick-dots li.slick-active button:before {
  opacity: 0.8;
  transform: scale(1.2);
}

/* Modern, clear arrow styles */
.slick-prev,
.slick-next {
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.8) !important;
  border-radius: 50%;
  z-index: 1;
  transition: all 0.3s ease;
  opacity: 0.7;
}

.slick-prev:hover,
.slick-next:hover {
  background: rgba(0, 0, 0, 0.95) !important;
  opacity: 1;
  transform: scale(1.1);
}

/* Custom arrow icons */
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 30px;
  line-height: 1;
  color: white;
  opacity: 1;
}

.slick-prev:before {
  content: "←";
}

.slick-next:before {
  content: "→";
}

/* Position the arrows */
.slick-prev {
  left: 30px;
}

.slick-next {
  right: 30px;
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
  .slick-prev,
  .slick-next {
    width: 40px;
    height: 40px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 24px;
  }

  .slick-prev {
    left: 15px;
  }

  .slick-next {
    right: 15px;
  }
}

.separator2 {
  width: 100%;
  max-width: 1200px;
  height: 1px;
  background: linear-gradient(
    to right,
    transparent,
    rgba(0, 0, 0, 0.1) 20%,
    rgba(0, 0, 0, 0.1) 80%,
    transparent
  );
  margin: 80px auto 0;
}

@media screen and (min-width: 1800px) {
  .projects h2 {
    font-size: 55px;
  }

  .project-image {
    height: 500px;
  }
}

.projects-header {
  text-align: center;
  margin-bottom: 60px;
}

.projects-subtitle {
  font-size: 1.2rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
}

/* Modal styles */
.image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.image-modal {
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
}

.image-modal img {
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
  border-radius: 8px;
}

.close-button {
  position: absolute;
  top: -40px;
  right: -40px;
  background: none;
  border: none;
  color: white;
  font-size: 40px;
  cursor: pointer;
  padding: 10px;
  transition: transform 0.3s ease;
}

.close-button:hover {
  transform: scale(1.1);
}

/* Update slider settings for better spacing */
.slick-list {
  margin: 0 -30px;
}

.slick-slide > div {
  margin: 0 30px;
}
