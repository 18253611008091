.services-section {
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  min-height: 100vh;
}

.services-header {
  text-align: center;
  margin-bottom: 4rem;
}

.services-title {
  font-size: 3rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.title-underline {
  width: 100px;
  height: 4px;
  background: linear-gradient(90deg, #3498db, #2ecc71);
  margin: 0 auto;
  border-radius: 2px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.service-card {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;
}

.service-content {
  padding: 2rem;
  flex: 1;
}

.service-icon {
  font-size: 2.5rem;
  display: inline-block;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #3498db, #2ecc71);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.service-title {
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.service-description {
  color: #666;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.learn-more-btn {
  background: linear-gradient(135deg, #3498db, #2ecc71);
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.service-image-container {
  position: relative;
  height: 300px;
  overflow: hidden;
  border-radius: 0 0 20px 20px;
  cursor: pointer;
}

.service-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(52, 152, 219, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.view-project {
  color: white;
  font-weight: 500;
  font-size: 1.2rem;
  transform: translateY(20px);
  transition: transform 0.3s ease;
}

.service-image-container:hover .image-overlay {
  opacity: 1;
  pointer-events: auto;
}

.service-card:hover .image-overlay {
  opacity: 1;
}

.service-card:hover .view-project {
  transform: translateY(0);
}

.service-card:hover .service-image {
  transform: scale(1.1);
}

/* Glass morphism effect */
.service-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(10px);
  z-index: -1;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .services-title {
    font-size: 2rem;
  }

  .services-grid {
    grid-template-columns: 1fr;
    padding: 1rem;
  }

  .service-card {
    margin-bottom: 2rem;
  }

  .modal-content {
    width: 95vw;
  }

  .service-image-container {
    height: 200px;
  }
}

/* Animation Keyframes */
@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(52, 152, 219, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(52, 152, 219, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(52, 152, 219, 0);
  }
}

/* Large Screen Optimizations */
@media screen and (min-width: 1800px) {
  .services-grid {
    grid-template-columns: repeat(3, 1fr);
    max-width: 1800px;
  }

  .services-title {
    font-size: 3.5rem;
  }

  .service-title {
    font-size: 1.8rem;
  }

  .service-description {
    font-size: 1.2rem;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 2rem;
}

.modal-content {
  background: white;
  border-radius: 20px;
  overflow: hidden;
  max-width: 90vw;
  max-height: 90vh;
  position: relative;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.modal-image {
  width: 100%;
  height: auto;
  max-height: 80vh;
  object-fit: contain;
}

.modal-details {
  padding: 1.5rem;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  padding: 0.5rem;
  line-height: 1;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(4px);
}
