/* Footer.css */
.footer {
  background-color: #000000;
  padding: 3rem 1rem;
  margin-top: auto;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.footer-logo {
  width: 140px;
  height: auto;
  opacity: 0.9;
  filter: invert(1);
}

.footer-contact {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #ffffff;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
}

.footer-contact a {
  color: #ffffff;
  text-decoration: none;
  opacity: 0.9;
  transition: opacity 0.3s ease;
}

.footer-contact a:hover {
  opacity: 1;
}

.divider {
  color: #ffffff;
  opacity: 0.5;
}

.footer-copyright {
  color: #ffffff;
  font-size: 1.1rem;
  letter-spacing: 1px;
  opacity: 0.9;
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
  .footer {
    padding: 2rem 1rem;
  }

  .footer-logo {
    width: 120px;
  }

  .footer-contact {
    flex-direction: column;
    gap: 0.5rem;
    text-align: center;
  }

  .divider {
    display: none;
  }

  .footer-copyright {
    font-size: 1rem;
  }
}
