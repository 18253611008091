.trusted-by {
  padding: 0;
  margin: 0;
  background-color: #fff;
  font-family: Arial, sans-serif;
}

.trusted-by h2 {
  font-size: 40px;
  color: #333;
  margin-bottom: 40px;
  text-align: center;
}

.companies {
  justify-content: center;
  gap: 15px;
}

.logo-container2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: auto;
  margin-bottom: 20px;
  background: transparent;
}

.company-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
  filter: none;
  mix-blend-mode: normal;
}

.farnek-logo {
  width: 300px;
  margin-top: 16.5%;
  margin-right: -7.5px;
  margin-left: 0px;
}

.alfuttaim-logo {
  width: 340px;
  margin-top: 4%;
  margin-right: -7.5px;
  margin-left: 0;
}

.Enova {
  width: 250px;
  margin-top: -4%;
  margin-right: 0px;
  margin-left: 0px;
  padding: 80px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.Emrill {
  width: 350px;
  margin-top: -6%;
  margin-right: -7.5px;
  margin-left: -7.5px;
}

.Emaar {
  width: 280px;
  height: 200px;
  margin-top: 0%;
  margin-right: -7.5px;
  margin-left: 0px;
}

.DubaiMall {
  width: 400px;
  height: 160px;
  margin-top: 2%;
  margin-right: -7.5px;
  margin-left: -7.5px;
}

/* Media Query for Mobile Screens */
@media screen and (max-width: 768px) {
  .mobile-carousel {
    display: block;
  }
  .farnek-logo {
    width: 200px;
    height: 50px;
    top: -44px;
    margin-left: 100px;
    margin-right: 100px;
    padding-left: 100px;
    padding-right: 100px;
  }
  .logo-container2 {
    width: 80%;
    height: auto;
    margin: 0 auto;
    background: transparent;
  }

  .company-logo {
    width: 100%;
    height: 150px;
    object-fit: contain;
    filter: none;
  }

  .alfuttaim-logo {
    width: 260px;
    top: -22px;

    margin-left: 100px;
    margin-right: 100px;
    padding-left: 100px;
    padding-right: 100px;
  }

  .Emrill {
    margin: 0px;
    margin-top: 0px;
    align-items: center;

    margin-left: 100px;
    margin-right: 100px;
    padding-left: 100px;
    padding-right: 100px;
  }

  .Emaar {
    width: 240px;
    margin: 0px;
    align-items: center;
    margin-left: 100px;
    margin-right: 100px;
    padding-left: 100px;
    padding-right: 100px;
  }

  .DubaiMall {
    align-items: center;

    margin-left: 100px;
    margin-right: 100px;
    padding-left: 100px;
    padding-right: 100px;
  }

  .Enova {
    margin-top: 5%;
    margin-left: 100px;
    margin-right: 100px;
    padding-left: 100px;
    padding-right: 100px;
  }
  .desktop-view {
    display: none;
  }
  .companies {
    align-items: center;
  }
}

@media screen and (min-width: 1800px) {
  .trusted-by h2 {
    font-size: 55px;
  }
}
